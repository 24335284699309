import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {toPriceStr} from '@/utils';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet, View} from 'react-native';
import {postRankingList} from '../home.service';
import {WinnerItem} from '../home.type';
import homeStore from '../home.refresh.service';
import HomeAnimatedTop from './home-animated-top';

const style = StyleSheet.create({
  title: {
    backgroundColor: '#1E7C2D',
    borderColor: '#DFC055',
    borderWidth: 3,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  content: {
    backgroundColor: '#D2B363',
  },
  bigWinContainer: {
    height: globalStore.isWeb ? 296 : 320,
  },
});

const HomeBigWin = () => {
  const i18n = useTranslation();
  const [biggestWinList, setBiggestWinList] = React.useState<
    (WinnerItem | null)[]
  >([]);
  const [jackpotList, setJackpotList] = React.useState<(WinnerItem | null)[]>(
    [],
  );
  const [bouns, setBouns] = React.useState(
    toPriceStr(308734820.18, {
      thousands: true,
      showCurrency: false,
    }),
  );
  useEffect(() => {}, []);
  const getData = () => {
    postRankingList()
      .then(res => {
        setBiggestWinList(
          res.biggestList.length > 0 ? res.biggestList : Array(5).fill(null),
        );
        setJackpotList(
          res.jackpotList.length > 0 ? res.jackpotList : Array(5).fill(null),
        );
        setBouns(
          toPriceStr(res.totalBonus ? res.totalBonus : 324794984, {
            thousands: true,
            showCurrency: false,
          }),
        );
      })
      .finally(() => homeStore.refreshEnd.next());
  };

  React.useEffect(() => {
    getData();
    const sub = homeStore.refresh.subscribe(() => {
      getData();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <View style={[theme.padding.lrl]}>
      <View style={[theme.padding.lrxxl]}>
        <View
          style={[
            style.title,
            theme.padding.tbxxs,
            theme.flex.row,
            theme.flex.center,
          ]}>
          <View>
            <Text
              size="large"
              calc
              fontFamily="fontAnybody"
              style={[{lineHeight: theme.fontSize.l}]}
              color={theme.basicColor.white}>
              BIG
            </Text>
            <Text
              size="large"
              calc
              fontFamily="fontAnybody"
              style={[{lineHeight: theme.fontSize.l}]}
              color={theme.basicColor.white}>
              WIN
            </Text>
          </View>
          <Text
            size="large"
            calc
            style={[theme.margin.leftl, theme.margin.rightxxs]}
            color={'#FFF27D'}>
            {globalStore.currency}
          </Text>
          <Text fontSize={26} blod calc fontFamily="fontDin" color={'#FFF27D'}>
            {bouns}
          </Text>
        </View>
      </View>
      <View style={[style.content, theme.padding.xxs, theme.flex.row]}>
        {[biggestWinList, jackpotList].map((list, i) => {
          return (
            <View
              key={i}
              style={[
                theme.flex.flex1,
                i === 0 && theme.margin.rightxxs,
                theme.background.white,
                theme.borderRadius.m,
                theme.padding.btml,
              ]}>
              <View
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  theme.padding.s,
                ]}>
                {i === 0 ? (
                  <>
                    <Image
                      style={[theme.icon.m, theme.margin.rights]}
                      source={require('@assets/imgs/home/biggest-wins.webp')}
                    />
                    <Text blod size="medium" calc>
                      {i18n.t('home.label.biggestWins')}
                    </Text>
                  </>
                ) : (
                  <>
                    <Image
                      style={[theme.icon.m, theme.margin.rights]}
                      source={require('@assets/imgs/home/jackpot-games.webp')}
                    />
                    <Text blod size="medium" calc>
                      {i18n.t('home.label.jackpotGames')}
                    </Text>
                  </>
                )}
              </View>
              {list.length > 0 && <HomeAnimatedTop list={list} />}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default HomeBigWin;
